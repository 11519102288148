import React from 'react';
import { Switch, Route } from 'react-router-dom';
import '../App.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Footer from './Footer';
import LandingPage from './LandingPage';

import {
    Box,
} from '@mui/material';
import { AppContext } from '../contexts/AppContext';
import RefundPolicy from './RefundPolicy';

function App() {
    const theme = createTheme({
        'components': {
            'MuiTypography': {
                'styleOverrides': {
                    'body2': {
                        'marginBlockStart': '1em',
                        'marginBlocEend': '1em',
                        'fontFamily': `'Lato', sans-serif`,
                    },
                    'h1': {
                        'fontFamily': `'Lato', sans-serif`,
                    },
                    'h2': {
                        'fontFamily': `'Lato', sans-serif`,
                        'fontSize': '32px',
                        'fontWeight': 400,
                        'margin': 0,
                    },
                },
            },
        },
        'palette': {
            'white': {
                'main': 'white'
            }
        }
    });

    const config = {
        is_campaign_active: false,
        tickets_url: 'https://indiegiving2025.eventbrite.com',
    };

    return <AppContext.Provider value={{
        ...config,
    }}>
        <ThemeProvider theme={ theme }>
            <Box
                style={{
                    'paddingLeft': 0,
                    'paddingRight': 0,
                    'maxWidth': 'unset',
                    'backgroundColor': '#33B4DE',
                }}
            >
                <Switch>
                    <Route path='/refund-policy'>
                        <RefundPolicy/>
                    </Route>

                    <Route path='/'>
                        <LandingPage/>
                    </Route>
                </Switch>
                <Footer/>
            </Box>
        </ThemeProvider>
    </AppContext.Provider>;
}

export default App;
