import React, { useContext } from 'react';
import {
    Box,
    Grid,
    Typography,
} from '@mui/material';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { AppContext } from '../contexts/AppContext';

function RightDetailsBox() {
    const { is_campaign_active, tickets_url } = useContext(AppContext);

    return <Grid item 
        xs={ 12 } 
        md={ 6 }
        marginTop={{
            'xs': '30px',
            'md': 0,
        }}
        paddingLeft={{
            'md': '15px'
        }}
    >
        <Box
            component='div'
            sx={{
                'boxSizing': 'border-box',
                'position': 'relative',
            }}
        >
            <Box sx={{
                'backgroundColor': '#CEF3FF',
                'width': '100%',
                'height': 'auto',
                'position': 'relative',
                'paddingBottom': '20px',
                'color': '#525D60',
            }}>
                <Box>
                    <Typography variant='h1'
                        sx={{
                            'fontSize': '19px',
                            'fontWeight': '300',
                            'lineHeight': '30px',
                            'paddingLeft': '30px',
                            'top': {
                                'xs': '100px',
                                'sm': '30px',
                            },
                            'position': 'relative',
                            'display': 'inline',
                        }}
                    >
                        { is_campaign_active ? 'Indie Giving Details' : 'See you next year!' }
                    </Typography>
                    { is_campaign_active && <Box sx={{
                        'backgroundColor': '#DD56AF',
                        'cursor': 'pointer',
                        'borderRadius': '6px',
                        'color': '#FFFFFF',
                        'fontSize': '22px',
                        'fontWeight': '400',
                        'lineHeight': '44px',
                        'right': '0',
                        'textAlign': 'center',
                        'px': '20px',
                        'marginTop': {
                            'xs': '0',
                            'sm': '15px',
                        },
                        'marginBottom': '0',
                        'marginRight': {
                            'xs': 'auto',
                            'sm': '30px',
                        },
                        'marginLeft': {
                            'xs': '30px',
                            'sm': '0',
                        },
                        'maxWidth': {
                            'xs': '240px',
                            'sm': undefined,
                        },
                        'display': {
                            'xs': 'block',
                            'sm': 'inline',
                        },
                        'position': {
                            'sm': 'absolute',
                        },
                    }}
                    onClick={() => window.open(tickets_url, '_blank')}>
                        Purchase Tickets Here
                    </Box> }
                </Box>
                <div style={{
                    'backgroundColor': '#A9CFDB',
                    'height': '1px',
                    'display': 'block',
                    'position': 'relative',
                    'margin': '70px 30px 25px 30px',
                }}/>
                <ul className='indie-giving-list' style={{
                    'listStyleType': 'none',
                }}>
                    <li className='indie-giving-list-content'>1 GDC All Access pass</li>
                    <li className='indie-giving-list-content'>Charity event participation</li>
                    <li className='indie-giving-list-content'>Awesome t-shirts</li>
                </ul>
            </Box>
            { !is_campaign_active && <Box sx={{
                'backgroundColor': '#237A96',
                'paddingX': '20px',
                'paddingBottom': '10px',
                'position': 'relative',
            }}>
                <Typography variant='h1'
                    sx={{
                        'color': '#FFFFFF',
                        'fontSize': '18px',
                        'fontWeight': '400',
                        'lineHeight': '24px',
                        'paddingTop': '40px',
                        'paddingBottom': '25px',
                        'margin': '0',
                    }}
                >
                    { is_campaign_active ? 'Signup for Indie Giving' : 'If you would like to participate next year, please drop us a line.' }
                </Typography>
            </Box> }
        </Box>
        
        <Typography variant='body2' paddingTop='5px'>
            <Link to='/refund-policy' style={{
                'color': '#ffffff',
                'fontWeight': 'bold',
                'textDecoration': 'unset',
            }}>» Important information regarding GDC or Indie Giving cancellations</Link>
        </Typography>
    </Grid>
}

export default RightDetailsBox;